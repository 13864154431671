import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection } from '../../../../components';
import { Header, SettingUsersContainer } from '../../../../containers';
import InviteUsersModal from './inviteUsersModal';

const UsersWrapper = ({ openModal, t }) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">

          {t('Settings')} - {t('user:User')}
        </h1>
        <nav className="header-nav header-nav--user">
          <NavLink
            activeClassName="header-nav__link--active"
            className="header-nav__link"
            to="/preferences/"
          >

            {t('Users')}
          </NavLink>
          <NavLink
            activeClassName="header-nav__link--active"
            className="header-nav__link"
            to="/preferences/bots"
          >

            {t('bots:Bots')}
          </NavLink>
        </nav>
      </>
      <ButtonsCollection.ButtonBrill
        // asLink
        // href="/preferences/users/invite-user"
        type="button"
        renderMobileButton
        className="user-header__button-invite"
        onClick={() => openModal('inviteUsersModal')}
      >
        {t('user:Create user')}
      </ButtonsCollection.ButtonBrill>
    </Header>
    <div className="main-container__content">
      <SettingUsersContainer />
    </div>
    <InviteUsersModal />
  </>
);

UsersWrapper.propTypes = {
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default UsersWrapper;
