import React from 'react';

import PropTypes from 'prop-types';
import { ButtonsCollection, FormsCollection, ModalWindow } from '../../../../../components';

import './style.sass';

const ModalView = ({
  isOpen,
  onClose,
  handleChange,
  handleSubmit,
  values,
  touched,
  errors,
  modalName,
  title,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName={modalName}
    title={t(title)}
    className="modal-window__bots"
  >
    <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
      <div className="modal-bots__row">
        <div className="modal-bots__col">
          <span className="modal-bots__text">{t('Bot`s name')}</span>
          <FormsCollection.Input
            placeholder={t('Bot`s name')}
            className="modal-bots__input"
            id="bot_title"
            onChange={handleChange}
            name="title"
            value={values.title}
            touched={touched.title}
            error={errors.title}
          />
        </div>
      </div>
      <div className="modal-bots__row">
        <div className="modal-bots__col">
          <span className="modal-bots__text">{t('Bot`s token')}</span>
          <FormsCollection.Input
            placeholder={t('Bot`s token')}
            id="bot_token"
            className="modal-bots__input"
            touched={touched.token}
            onChange={handleChange}
            name="token"
            value={values.token}
            error={errors.token}
          />
        </div>
      </div>
      <div className="modal-bots__btn-container">
        <ButtonsCollection.ButtonBrill
          className="modal-bots__btn button--sm"
          type="submit"
        >{t('SUBMIT')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.oneOfType([PropTypes.shape({
    title: PropTypes.string,
    token: PropTypes.string,
  }), PropTypes.func]),
  errors: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    token: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  }),
  t: PropTypes.func.isRequired,
};

ModalView.defaultProps = {
  values: PropTypes.shape({
    title: '',
    token: '',
  }),
  errors: PropTypes.shape({
    title: '',
    token: '',
  }),
};

export default ModalView;
