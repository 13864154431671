import React from 'react';

import PropTypes from 'prop-types';

import {
  eighthImage,
  eleventhImage,
  fifthImage,
  firstImage,
  fourthImage,
  ninthImage,
  sixthImage,
  secondImage,
  tenthImage,
  thirdImage,
  twelfthImage,
} from '../../../../constants/telegramImages';

import './style.sass';

const GuideInfo = ({ openModalHandler }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">Зайдіть у Telegram, додайте до своїх контактів @BotFather та введіть команду /start.</span>
      <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={firstImage} alt="firstImage" />
      </button>
      <span className="guide__text">Після цього Ви отримаєте весь список команд.</span>
      <button onClick={() => openModalHandler(secondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={secondImage} alt="secondImage" />
      </button>
      <span className="guide__text">Напишіть команду /newbot для створення нового бота.</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">Придумайте назву для Вашого бота.</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">Придумайте username, яке має закінчуватися словом bot (або Bot).</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <span className="guide__text">Збережіть token, який видасть бот.</span>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">Зайдіть у свій акаунт в SmartexApp для інтеграції бота</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">Перейдіть в Preferences - Bots та натисніть на кнопку Add account</span>
      <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={ninthImage} alt="ninthImage" />
      </button>
      <span className="guide__text">Напишіть Bot’s name, вставте збережений Bot’s token та натисніть Submit.</span>
      <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={tenthImage} alt="tenthImage" />
      </button>
      <span className="guide__text">Напишіть повідомлення боту в Telegram.</span>
      <button onClick={() => openModalHandler(eleventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eleventhImage} alt="eleventhImage" />
      </button>
      <span className="guide__text">Вітаємо Ви отримали повідомлення в SmartexApp від Вашого бота. Натисніть кнопку Join channel для того, щоб розпочати розмову.</span>
      <button onClick={() => openModalHandler(twelfthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twelfthImage} alt="twelfthImage" />
      </button>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
};

export default GuideInfo;
