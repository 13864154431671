import {
  compose, getContext, withHandlers,
} from 'recompose';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GuideInfo from './guideInfo';
import { openModal } from '../../../../state/ui/actions';

const openModalHandler = ({ setOpenModal, setImage }) => (image) => {
  setImage(image);
  setOpenModal('imageModal');
};

const mapDispatchToProps = ({
  setOpenModal: openModal,
});

const onCopyLinkClick = () => link => async () => {
  await navigator.clipboard.writeText(link);
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  getContext({
    setImage: PropTypes.func,
  }),
  withHandlers({
    openModalHandler,
    onCopyLinkClick,
  }),
);

export default enhance(GuideInfo);
