import {
  compose, withContext, withHandlers, withState,
} from 'recompose';

import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Guide from './guide';
import { history } from '../../store';

const goBackHandler = () => () => history.goBack();

const enhancer = compose(
  withNamespaces('common'),
  withState('image', 'setImage', null),
  withHandlers({
    goBackHandler,
  }),
  withContext({
    image: PropTypes.string,
    setImage: PropTypes.func.isRequired,
    goBackHandler: PropTypes.func.isRequired,
  },
  ({
    // eslint-disable-next-line no-shadow
    setImage, image, goBackHandler,
  }) => ({
    setImage, image, goBackHandler,
  })),
);

export default enhancer(Guide);
