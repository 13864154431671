import * as yup from 'yup';
import { translateHelpers } from '../../../../../utils/helpers';

const rules = yup.object().shape({
  title: yup
    .string()
    .required(translateHelpers.t('bots', 'Title is required'))
    .min(5, translateHelpers.t('bots', 'Title is too short'))
    .min(5, translateHelpers.t('bots', 'Title is too long')),
  token: yup
    .string()
    .required(translateHelpers.t('bots', 'Webhook token is required')),
  page_id: yup
    .string()
    .required(translateHelpers.t('bots', 'Page id is required')),
  page_access_token: yup
    .string()
    .required(translateHelpers.t('bots', 'Page token is required')),
});

export default rules;
