import React from 'react';
import PropTypes from 'prop-types';

import {
  eighthImage,
  fifthImage,
  firstImage,
  fourthImage,
  ninthImage,
  sixthImage,
  secondImage,
  tenthImage,
  thirdImage,
} from '../../../../constants/viberImages';

import './style.sass';

const GuideInfo = ({ openModalHandler }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">Встановіть додаток Viber.</span>
      <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={firstImage} alt="firstImage" />
      </button>
      <span className="guide__text">Перейдіть на сайт розробки: <a href="https://partners.viber.com">partners.viber.com</a></span>
      <button onClick={() => openModalHandler(secondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={secondImage} alt="secondImage" />
      </button>
      <span className="guide__text">Введіть номер телефону та авторизуйтеся.</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">Viber надішле код підтвердження, введіть його, натисніть «Next».</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">Натисніть кнопку «Create Bot Account» і заповніть необхідні поля:</span>
      <ul className="guide__list">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <li className="guide__item">Account Name - Ім'я, яке буде відображатися в назві бота.</li>
        <li className="guide__item"> URI - Придумайте ідентифікатор, за яким можна буде знайти бота.</li>
        <li className="guide__item">Category - Виберіть одну з 7 основних категорій.</li>
        <li className="guide__item">Subcategory - Виберіть зі списку підкатегорію, в якій буде функціонувати Ваш бот.</li>
        <li className="guide__item">Language - Виберіть основну мову.</li>
        <li className="guide__item"> Account Description - Опишіть детально, бота якого Ви створили.</li>
        <li className="guide__item"> Website Address - За бажанням вкажіть адресу.</li>
        <li className="guide__item">Email Address - Адреса електронної пошти.</li>
        <li className="guide__item">Location - Локація, місце розташування</li>
      </ul>
      <span className="guide__text">Внизу ставимо галочку про прийняття угоди і натискаємо «Create».</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <span className="guide__text">Після цього ви отримаєте токен для аутентифікації Viber. Скопіюйте та збережіть його.</span>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">Зайдіть у свій акаунт в SmartexApp для інтеграції бота</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">Перейдіть в Preferences - Bots та натисніть на кнопку Add account</span>
      <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={ninthImage} alt="ninthImage" />
      </button>
      <span className="guide__text">Напишіть Bot’s name, вставте збережений Bot’s token та натисніть Submit.</span>
      <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={tenthImage} alt="tenthImage" />
      </button>
      <span className="guide__text">Перейдіть в додаток Viber та введіть в пошуку Account name Вашого бота.</span>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
};

export default GuideInfo;
