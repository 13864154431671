import { compose, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import {
  reduce, prop, path, or, equals, pickBy, filter, mapObjIndexed,
} from 'ramda';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import InviteUsersModal from './inviteUsersModal';
import { setUsersInviteRequest } from '../../../../../state/users/actions';
import { getInviteUsersError, getInviteUsersPending } from '../../../../../state/users/selectors';
import { getModal } from '../../../../../state/ui/selectors';
import { closeModal } from '../../../../../state/ui/actions';
import FIELD_NAME from './constants';

const errorsMessages = {
  required: 'Email is required',
  valid: 'Email address is not valid',
  unique: 'Email already exist',
};

const getFieldName = idField => `${FIELD_NAME}${idField}`;

const getErrorMessage = ({ type }) => {
  switch (type) {
    case 'required':
      return errorsMessages.required;
    case 'valid':
      return errorsMessages.valid;
    case 'unique':
      return errorsMessages.unique;
    default:
      return '';
  }
};

const lazyRulesSchema = (emails) => {
  const getRuleForField = (acc, id) => ({
    ...{
      [getFieldName(id)]: yup
        .string()
        .required({
          message: 'required',
        })
        .email({
          message: 'valid',
        }),
    },
    ...acc,
  });
  const getCollectionRules = fieldsCollection => reduce(getRuleForField, {}, fieldsCollection);
  const getYupObjectRules = collectionRules => yup.object().shape(collectionRules);
  return getYupObjectRules(getCollectionRules(emails));
};


const setFieldItem = () => newFieldItems => ({
  fieldsArray: newFieldItems,
});

const deleteFieldItem = ({ fieldsArray }) => (id) => {
  const checkFieldId = val => !equals(id, val);
  const getFieldsCollection = fieldsCollection => filter(checkFieldId, fieldsCollection);
  return {
    fieldsArray: getFieldsCollection(fieldsArray),
  };
};

const makeFieldsForFormik = ({ fieldsArray }) => {
  const setFieldToFormik = (acc, id) => ({ ...{ [getFieldName(id)]: '' }, ...acc });
  const getFieldsCollection = fieldsCollection => reduce(setFieldToFormik, {}, fieldsCollection);
  return getFieldsCollection(fieldsArray);
};

const formSubmit = (formValues, {
  resetForm,
  props: {
    usersInviteRequest, onCloseModal, setFieldItemStateHandler,
  },
}) => {
  const setEmailItem = val => ({ email: val });
  usersInviteRequest({
    emails: mapObjIndexed(setEmailItem, formValues),
  }, {
    callbacks: {
      success: () => {
        onCloseModal();
        resetForm();
        setFieldItemStateHandler([0]);
      },
    },
  });
};

const deleteField = ({ deleteFieldItemStateHandler, resetForm, values }) => (id) => {
  deleteFieldItemStateHandler(id);
  resetForm(pickBy((val, key) => key !== getFieldName(id), values));
};

const addFieldItem = ({ fieldsArray, setFieldItemStateHandler }) => ({ many }) => {
  const setNewIndexField = upCount => fieldsArray[fieldsArray.length - 1] + upCount;
  const newArray = many ? [setNewIndexField(1), setNewIndexField(2)] : [setNewIndexField(1)];
  setFieldItemStateHandler(fieldsArray
    .concat(newArray));
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('inviteUsersModal'),
  usersInviteErrors: getInviteUsersError(state),
  isPending: getInviteUsersPending(state),
});

const closeModalHandler = ({ resetForm, onCloseModal, setFieldItemStateHandler }) => () => {
  resetForm({});
  onCloseModal();
  setFieldItemStateHandler([0]);
};
const mapDispatchToProps = ({
  onCloseModal: () => closeModal('inviteUsersModal'),
  usersInviteRequest: setUsersInviteRequest,
});

const getError = ({ errors, usersInviteErrors }) => (id) => {
  const yupError = prop('message', errors[getFieldName(id)]);
  const serverError = path([`emails.email${id}.email`, 'message'], usersInviteErrors);
  return getErrorMessage({ type: or(yupError, serverError) });
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withStateHandlers(() => ({
    fieldsArray: [0],
  }), {
    deleteFieldItemStateHandler: deleteFieldItem,
    setFieldItemStateHandler: setFieldItem,
  }),

  withFormik({
    mapPropsToValues: ({ fieldsArray }) => makeFieldsForFormik({ fieldsArray }),
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: ({ fieldsArray }) => lazyRulesSchema(fieldsArray),
    handleSubmit: formSubmit,
  }),
  withHandlers({
    addFieldItemHandler: addFieldItem,
    deleteFieldItemHandler: deleteField,
    getError,
    closeModalHandler,
  }),
);
export default enhance(InviteUsersModal);
