import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import UsersWrapper from './usersWrapper';
import { openModal } from '../../../../state/ui/actions';

const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  withRouter,
);

export default enhance(UsersWrapper);
