import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import { MODULES } from '../../constants/pemissions';
import withPermission from '../../utils/enchancers/withPemission';
import Settings from './settings';

const enhancer = compose(
  withPermission(MODULES.SETTINGS),
  withNamespaces(['common', 'bots']),
);

export default enhancer(Settings);
