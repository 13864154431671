import React from 'react';
import { values } from 'ramda';

import BotsList from '../botsList';
import {
  AddBotsModal, EditBotsModal, DeleteBotsModal, AddBotsModalFb, EditBotsModalFb,
} from '../modals';

import { MESSENGER_TYPES } from '../../../../constants/bots';

import './style.sass';

const BotsGrid = () => (
  <div className="bots-grid__wrapper">
    <ul className="bots-grid">
      {values(MESSENGER_TYPES).map(messengerId => (
        <BotsList
          key={messengerId}
          messengerId={messengerId}
        />
      ))}
    </ul>
    <AddBotsModal />
    <EditBotsModal />
    <DeleteBotsModal />
    <AddBotsModalFb />
    <EditBotsModalFb />
  </div>
);


export default BotsGrid;
