import React from 'react';
import { Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Layouts, NoInternetConnection,
} from '../../components';
import { LeftSideBar } from '../../containers';
import './style.sass';
import {
  UsersWrapper, UserWrapper, BotsWrapper,
} from './components';
import UserInvite from './components/userInvite';


const Settings = ({ t }) => (
  <Layouts.Main>
    <LeftSideBar>
      <nav className="sub-nav">
        <h3 className="sub-nav__title"> {t('General')}</h3>
        <NavLink className="sub-nav__link" activeClassName="sub-nav__link--active" to="/preferences/">
          {t('Users')}/{t('bots:Bots')}
        </NavLink>
      </nav>
    </LeftSideBar>
    <div className="main-container main-container--settings">
      <NoInternetConnection>
        <Switch>
          <Route exact path="/preferences/bots/" component={BotsWrapper} />
          <Route exact path="/preferences/users/invite-user/" component={UserInvite} />
          <Route exact path="/preferences/users/" component={UsersWrapper} />
          <Route exact path="/preferences/users/:userId/" component={UserWrapper} />
          <Route exact path="/preferences/" component={UsersWrapper} />
          <Route component={UsersWrapper} />
        </Switch>
      </NoInternetConnection>
    </div>
  </Layouts.Main>
);

Settings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Settings;
