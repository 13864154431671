import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import {
  map, propOr, values, pathOr, path,
} from 'ramda';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { isNotEmpty, notEqual } from 'ramda-extension';
import UserInvite from './userInvite';
import { settingUsersActions, settingUsersSelectors } from '../../../../state/settingUsers';
import { permissionsActions, permissionsSelectors } from '../../../../state/permissions';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { getErrors } from '../../../../utils/helpers/requestHelpers';
import { getErrorsForField } from '../../../../utils/helpers/formHelpers';
import rules from './rules';
import { withAutocomplete } from '../../../../utils/enchancers';
import { USER_PENDING } from '../../../../constants/users';
import { callNotification } from '../../../../utils/helpers/notifies';

const mapStateToProps = (state, { match: { params: { userId } } }) => ({
  user: propOr({}, userId, settingUsersSelectors.getSettingUsersEntities(state)),
  roles: permissionsSelectors.getRolesEntities(state),
  isPending: settingUsersSelectors.getCreateUserPending(state),
});

const mapDispatchToProps = {
  getUsersRequest: settingUsersActions.getSettingUserRequest,
  getRolesRequest: permissionsActions.getRolesRequest,
  createUserRequest: settingUsersActions.createUserRequest,
  push: routerPush,
};

const getInitialValues = ({
  roles,
  user: {
    first_name, last_name, username, role_id, id, email,
  },
}) => {
  const role = propOr({}, role_id, roles);
  return {
    first_name,
    last_name,
    userId: id,
    username,
    status: USER_PENDING,
    email,
    role_id: { ...role, value: role_id, label: role.name },
  };
};

const onSend = ({ role_id, ...formValues },
  { props: { match: { params: { userId } }, ...props } }) => {
  props.createUserRequest({ ...formValues, userId, role_id: role_id.value }, {
    callbacks: {
      error: compose(
        callNotification.error,
        props.setErrorFromBack, pathOr({}, ['response', 'data', 'result', 'errors']),
      ),
      success: ({ user }) => props.push(`/preferences/users/${user.id}/`),
    },
  });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withFormik({
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: onSend,
  }),
  withHandlers({
    getErrorField: ({
      errorFromBack,
      errors,
    }) => getErrorsForField({ ...errorFromBack, ...errors }),
  }),
  withAutocomplete({
    name: 'getRolesAutocomplete',
    action: (params, meta) => permissionsActions.getRolesRequest(params,
      { ...meta, isAutocomplete: true }),
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data', 'entities', 'roles']),
    ),
    searchField: 'name',
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRolesRequest();
    },
    componentDidUpdate(prevProps) {
      const { errorFromBack } = this.props;
      if (notEqual(errorFromBack, prevProps.errorFromBack) && isNotEmpty(errorFromBack)) {
        getErrors(errorFromBack);
        this.props.setErrors(getErrors(errorFromBack));
      }
    },
  }),
);

export default enhance(UserInvite);
