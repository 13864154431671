import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import Clients from './clients';
import { uiActions } from '../../state/ui';
import { userSelectors } from '../../state/user';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const mapStateToProps = state => ({
  currentUser: userSelectors.getUserData(state),
});

const customFieldsModalHandler = ({ setOpenModal }) => () => {
  setOpenModal('customFieldsModal');
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['clients', 'customFields']),
  withHandlers({
    customFieldsModal: customFieldsModalHandler,
  }),
);

export default enhancer(Clients);
