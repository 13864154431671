import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { NavLink } from 'react-router-dom';
import { Header, SettingsBotsContainer } from '../../../../containers';

const BotsWrapper = ({ t }) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">

          {`${t('Settings')} - ${t('bots:Bots')}`}
        </h1>
        <nav className="header-nav header-nav--user">
          <NavLink
            activeClassName="header-nav__link--active"
            className="header-nav__link"
            to="/preferences/users/"
          >
            {t('Users')}
          </NavLink>
          <NavLink
            activeClassName="header-nav__link--active"
            className="header-nav__link"
            to="/preferences/bots"
          >
            {t('bots:Bots')}
          </NavLink>
        </nav>
        </>
    </Header>
    <div className="main-container__content main-container__content--roles">
      <SettingsBotsContainer />
    </div>
  </>
);

BotsWrapper.propTypes = {
  t: PropTypes.func.isRequired,
};

export default BotsWrapper;
