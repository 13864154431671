import React from 'react';

import PropTypes from 'prop-types';

import {
  eighteenthImage,
  eighthImage,
  eleventhImage,
  fifteenthImage,
  fifthImage,
  firstImage,
  fourteenthImage,
  fourthImage,
  nineteenthImage,
  ninthImage,
  secondimage,
  seventeenthImage,
  seventhImage,
  sixteenthImage,
  sixthImage,
  tenthImage,
  thirdImage,
  thirteenthImage,
  thirtiethImage,
  twelfthImage,
  twentiethImage, twentyFifthImage,
  twentyFirstImage, twentyFourthImage,
  twentySecondImage, twentySeventhImage, twentySixthImage,
  twentyThirdImage, twentyNinthImage,
} from '../../../../constants/facebookImages';

import './style.sass';

const GuideInfo = ({ openModalHandler, onCopyLinkClick }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">Обов’язково потрібно мати звичайний аккаунт користувача в Facebook.</span>
      <span className="guide__text">Потрібно зареєструватися як розробник у Facebook за посиланням: <a href="https://developers.facebook.com/">https://developers.facebook.com/</a> і виконати дії, що вказані на скріншотах.
      </span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={firstImage} alt="firstImage" />
        </button>
        <button onClick={() => openModalHandler(secondimage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={secondimage} alt="secondImage" />
        </button>
      </div>
      <span className="guide__text">Після входу в Facebook for Developers потрібно створити свій додаток. Для цього потрібно виконати дії, як на скріншотах. Клікніть на My apps.</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">Клікніть на Add a new App або виберіть вже існуючий додаток.</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">При створенні нового додатка виберіть категорію Manage Business Integrations.</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <div className="guide__container">
        <ul className="guide__list">
        Після цього потрібно вказати:
          <li className="guide__item">назву додатку;</li>
          <li className="guide__item">контактний e-mail;</li>
          <li className="guide__item">Who can use your app?</li>
        </ul>
      </div>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">Після вдалого створення або вибору додатку з існуючих ви потрапите на сторінку свого додатку. Вам потрібно вибрати в лівому сайдбарі Settings - basic.</span>
      <button onClick={() => openModalHandler(seventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={seventhImage} alt="seventhImage" />
      </button>
      <span className="guide__text">В настройках потрібно в поле App domains ввести адресу <span title="Натисніть щоб скопіювати" onClick={onCopyLinkClick('https://api.smartex.firs.org.ua/')} className="copiable-link">бекенду Smartex</span> і після цього зберегти зміни.</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">Потрібно додати messenger до Вашого додатку, для цього клікніть на Products. Знайдіть Messenger та натисніть Set up.</span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={ninthImage} alt="ninthImage" />
        </button>
        <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={tenthImage} alt="tenthImage" />
        </button>
      </div>
      <span className="guide__text">Після цього потрібно створити сторінку для бізнесу в фейсбуці (або вибрати існуючу). Для цього натисніть на Create new page.</span>
      <button onClick={() => openModalHandler(eleventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eleventhImage} alt="eleventhImage" />
      </button>
      <span className="guide__text">При створенні сторінки введіть назви та категорію і натисніть Create page.</span>
      <button onClick={() => openModalHandler(twelfthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twelfthImage} alt="twelfthImage" />
      </button>
      <span className="guide__text">Після цього натисніть на кнопку Save, яка з’явиться замість кнопки Create page та перейдіть на свою сторінку в Facebook for developers.</span>
      <span className="guide__text">За потребою в Facebook for developers клікніть на Messenger - Settings.</span>
      <button onClick={() => openModalHandler(thirteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirteenthImage} alt="thirteenthImage" />
      </button>
      <span className="guide__text">Після цього натисніть на Add or remove pages та виберіть попередньо створену сторінку для бізнесу.</span>
      <button onClick={() => openModalHandler(fourteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourteenthImage} alt="fourteenthImage" />
      </button>
      <span className="guide__text">Якщо все зроблено правильно, повинна з’явитись ваша сторінка.</span>
      <button onClick={() => openModalHandler(fifteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifteenthImage} alt="fifteenthImage" />
      </button>
      <span className="guide__text">Далі потрібно згенерувати токен та зберегти його собі в текстовий файл.</span>
      <span className="guide__text">Для цього тиснемо на Generate Token. В Випадаючому вікні тиснемо на I understand, тоді на Copy (зберігаємо токен) і після цього тиснемо Done.</span>
      <button onClick={() => openModalHandler(sixteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixteenthImage} alt="sixteenthImage" />
      </button>
      <span className="guide__text">Далі потрібно додати дану сторінку в Smartex. Для цього переходимо в Preferences - Bots та тиснемо на кнопку Add account.
      </span>
      <button onClick={() => openModalHandler(seventeenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={seventeenthImage} alt="seventeenthImage" />
      </button>
      <div className="guide__container">
        <ul className="guide__list">В випадаючому вікні потрібно ввести наступні дані:
          <li className="guide__item">назва бота (довільна);</li>
          <li className="guide__item">токен для вебхука (довільний);</li>
          <li className="guide__item">
            <span className="guide__item--text">Page id (взяти в Facebook for developers як на скріншоті)</span>
            <button onClick={() => openModalHandler(eighteenthImage)} type="button" className="guide__container-btn">
              <img className="guide__image" src={eighteenthImage} alt="eighteenthImage" />
            </button>
          </li>
          <li>Page access token (токен, який ви зберегли в попередньому пункті).</li>
        </ul>
      </div>
      <span className="guide__text"> Після цього натиснути на кнопку Submit.</span>
      <button onClick={() => openModalHandler(nineteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={nineteenthImage} alt="nineteenthImage" />
      </button>
      <span className="guide__text">Якщо дані введені правильно, тоді бот повинен з’явитися, як на скріншоті.</span>
      <button onClick={() => openModalHandler(twentiethImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentiethImage} alt="twentiethImage" />
      </button>
      <span className="guide__text">Переходимо в аккаунт Facebook for developers тиснемо на Messenger - Settings та тиснемо на кнопку Add callback URL</span>
      <button onClick={() => openModalHandler(twentyFirstImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={twentyFirstImage} alt="twentyFirstImage" />
      </button>
      <div className="guide-__container">
        <ul className="guide__list">
      В випадаючому вікні потрібно ввести два поля:
          <li className="guide__item" id="callback-url"><span title="Натисніть щоб скопіювати" onClick={onCopyLinkClick('https://api.smartex.firs.org.ua/webhook/facebook/')} className="copiable-link">Callback URL</span> (адреса бекенду Smartex + ‘/webhook/facebook’)</li>
          <li className="guide__item">Verify Token (токен для вебхука, який попередньо вводили при додаванні бота в Smartex).</li>
        </ul>
      </div>
      <button onClick={() => openModalHandler(twentySecondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySecondImage} alt="twentySecondImage" />
      </button>
      <span className="guide__text">Після цього тиснемо на кнопку Verify and Save.</span>
      <span className="guide__text">Якщо все зроблено правильно повинно з’явитись кнопка для додавання Subscription як на скріншоті, тиснемо на неї.</span>
      <button onClick={() => openModalHandler(twentyThirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyThirdImage} alt="twentyThirdImage" />
      </button>
      <span className="guide__text">В випадаючому вікні вибираємо Subscriptions як на скріншоті і тиснемо Save.</span>
      <button onClick={() => openModalHandler(twentyFourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyFourthImage} alt="twentyFourthImage" />
      </button>
      <span className="guide__text">В блоці Built-in NLP обираємо нашу сторінку як на скріншоті.</span>
      <button onClick={() => openModalHandler(twentyFifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyFifthImage} alt="twentyFifthImage" />
      </button>
      <span className="guide__text">І клікаємо на перемикач, щоб ввімкнути  Built-in NLP.</span>
      <button onClick={() => openModalHandler(twentySixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySixthImage} alt="twentySixthImage" />
      </button>
      <span className="guide__text">В блоці App Review for Messenger додаємо Submissions (скріншот).</span>
      <button onClick={() => openModalHandler(twentySeventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySeventhImage} alt="twentySeventhImage" />
      </button>
      <span className="guide__text">Після цього перезавантажуємо сторінку.</span>
      <span className="guide__text">В Facebook Messenger  від свого імені пишемо на створену бізнес сторінку. Якщо все було зроблено правильно, повідомлення будуть приходити на Smartex.</span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(twentyNinthImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={twentyNinthImage} alt="twentyNinthImage" />
        </button>
        <button onClick={() => openModalHandler(thirtiethImage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={thirtiethImage} alt="thirtiethImage" />
        </button>
      </div>
      <span className="guide__text">Вітаємо!</span>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
};


export default GuideInfo;
